import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PostCard from '../components/post-card/post-card';
import SEO from '../components/seo';
import { TagPostsWrapper, TagPageHeading, TagName } from './templates.style';

const Tags = ({ pageContext, data }: any) => {
  const { tagName } = pageContext;
  const { edges, totalCount } = data.allContentfulBlogPost;
  const { defaultTitle, defaultDescription } = data.site.siteMetadata;

  return (
    <Layout>
      <SEO
        title={tagName + ' | ' + defaultTitle}
        description={defaultDescription}
      />

      <TagPostsWrapper>
        <TagPageHeading>
          <TagName>{tagName}</TagName>
          {`A collection of ${totalCount} post`}
        </TagPageHeading>
        {edges.map(({ node }: any) => (
          <PostCard
            key={node.slug}
            title={node.title}
            url={`/${node.slug}`}
            description={node.excerpt.excerpt}
            date={node.createdAt}
            tags={node.tags}
          />
        ))}
      </TagPostsWrapper>
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query ($tag: String) {
    allContentfulBlogPost(
      limit: 2000
      sort: { fields: createdAt, order: DESC }
      filter: { tags: { elemMatch: { slug: { eq: $tag } } } }
    ) {
      totalCount
      edges {
        node {
          excerpt {
            excerpt
          }
          slug
          createdAt(formatString: "DD [<span>] MMMM [</span>]")
          title
          tags {
            slug
            name
          }
        }
      }
    }
    site {
      siteMetadata {
        defaultTitle
        defaultDescription
      }
    }
  }
`;
